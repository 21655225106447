import { db } from "./contexts/AuthContext";
import {
  doc,
  getDoc,
  addDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  collection,
} from "firebase/firestore";

export async function getItems(collectionName) {
  const items = await getDocs(collection(db, collectionName));
  const data = [];
  items.forEach((doc) => {
    data.push({ ...doc.data(), id: doc.id });
  });
  return data;
}

export async function getItem(collectionName, id) {
  const ref = doc(db, collectionName, id);
  let data = await getDoc(ref);
  return data.data();
}

export async function addItem(collectionName, data) {
  const docRef = await addDoc(collection(db, collectionName), data);
  return docRef;
}

export async function updateItem(collectionName, id, data) {
  const ref = doc(db, collectionName, id);
  const res = await updateDoc(ref, data);
  return res;
}

export async function deleteItem(collectionName, id) {
  const ref = doc(db, collectionName, id);
  await deleteDoc(ref);
}

export async function editItem(collectionName, id, data) {
  const ref = doc(db, collectionName, id);
  await updateDoc(ref, data);
}
