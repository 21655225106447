import React from "react";
import { Form } from "react-bootstrap";

export default function ({
	setWebsite,
	setEmail,
	setName,
	setPhone,
	validation,
	website,
	email,
	phone,
	name,
}) {
	return (
		<Form className='form-fields'>
			<div className='row'>
				<div className='col-lg-12'>
					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label className='text-primary w-100 d-flex justify-content-between'>
							Website
						</Form.Label>
						<Form.Control
							onInput={(e) => setWebsite(e.target.value)}
							type='text'
							placeholder='website.com'
							isInvalid={validation && !website}
						/>
						<Form.Control.Feedback type='invalid'>
							Verplicht in te vullen
						</Form.Control.Feedback>
					</Form.Group>
				</div>
				<div className='col-lg-6'>
					<Form.Group className='mb-3' controlId='formBasicPassword'>
						<Form.Label>Email</Form.Label>
						<Form.Control
							onInput={(e) => setEmail(e.target.value)}
							type='email'
							placeholder='example@email.com'
							isInvalid={validation && !email}
						/>
						<Form.Control.Feedback type='invalid'>
							Verplicht in te vullen
						</Form.Control.Feedback>
					</Form.Group>
				</div>
				<div className='col-lg-6'>
					<Form.Group className='mb-3' controlId='formBasicPassword'>
						<Form.Label>Telefoon</Form.Label>
						<Form.Control
							onInput={(e) => setPhone(e.target.value)}
							type='text'
							placeholder='xxx-xxx-xxxx'
							isInvalid={validation && !phone}
						/>
						<Form.Control.Feedback type='invalid'>
							Verplicht in te vullen
						</Form.Control.Feedback>
					</Form.Group>
				</div>
				<div className='col-lg-12'>
					<Form.Group className='mb-3' controlId='formBasicPassword'>
						<Form.Label>Contactpersoon</Form.Label>
						<Form.Control
							onInput={(e) => setName(e.target.value)}
							type='text'
							placeholder='Jan Janssen'
							isInvalid={validation && !name}
						/>
						<Form.Control.Feedback type='invalid'>
							Verplicht in te vullen
						</Form.Control.Feedback>
					</Form.Group>
				</div>
			</div>
		</Form>
	);
}
