import React from "react";

export default function ({ type }) {
	return (
		<div className='row'>
			{type === "startup" && (
				<>
					<div className='col-lg-6'>
						<ul className='list-unstyled form-list mb-0'>
							{process.env.REACT_APP_STARTUP_INFORMATIONS_1.split("|").map(
								(item) => (
									<li className='pb-sm-2 small'>
										<i className='bi bi-2x bi-check text-danger'></i>
										{item}
									</li>
								)
							)}
						</ul>
					</div>
					<div className='col-lg-6'>
						<ul className='list-unstyled'>
							{process.env.REACT_APP_STARTUP_INFORMATIONS_2.split("|").map(
								(item) => (
									<li className='pb-sm-2 small'>
										<i className='bi bi-2x bi-check text-danger'></i>
										{item}
									</li>
								)
							)}
						</ul>
					</div>
				</>
			)}
			{type === "premium" && (
				<>
					<div className='col-lg-6'>
						<ul className='list-unstyled form-list mb-0'>
							{process.env.REACT_APP_PREMIUM_INFORMATIONS_1.split("|").map(
								(item) => (
									<li className='pb-sm-2 small'>
										<i className='bi bi-2x bi-check text-danger'></i>
										{item}
									</li>
								)
							)}
						</ul>
					</div>
					<div className='col-lg-6'>
						<ul className='list-unstyled'>
							{process.env.REACT_APP_PREMIUM_INFORMATIONS_2.split("|").map(
								(item) => (
									<li className='pb-sm-2 small'>
										<i className='bi bi-2x bi-check text-danger'></i>
										{item}
									</li>
								)
							)}
						</ul>
					</div>
				</>
			)}
			{type === "enterprise" && (
				<>
					<div className='col-lg-6'>
						<ul className='list-unstyled form-list mb-0'>
							{process.env.REACT_APP_ENTERPRISE_INFORMATIONS_1.split("|").map(
								(item) => (
									<li className='pb-sm-2 small'>
										<i className='bi bi-2x bi-check text-danger'></i>
										{item}
									</li>
								)
							)}
						</ul>
					</div>
					<div className='col-lg-6'>
						<ul className='list-unstyled'>
							{process.env.REACT_APP_ENTERPRISE_INFORMATIONS_2.split("|").map(
								(item) => (
									<li className='pb-sm-2 small'>
										<i className='bi bi-2x bi-check text-danger'></i>
										{item}
									</li>
								)
							)}
						</ul>
					</div>
				</>
			)}
		</div>
	);
}
