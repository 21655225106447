import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import Step1 from "./Step1";
import Step2 from "./Step2";
import axios from "axios";
import { addItem, updateItem } from "../functions";

function App({ step, setStep, setActive, type }) {
	let defaultWards = Number(process.env.REACT_APP_DEFAULT_WORDS),
		pricePerWards = Number(process.env.REACT_APP_PRICE_PER_WORDS),
		pricePerPage = Number(process.env.REACT_APP_PRICE_PER_PAGE),
		pricePerMonthlySeoReport = Number(
			process.env.REACT_APP_PRICE_PER_MONTHLY_SEO_REPORT
		),
		pricePerPageMonthly = Number(process.env.REACT_APP_PRICE_PER_PAGE_MONTHLY);

	const [defaultLandingPages, setDefaultLandingPages] = useState(0);
	const [defaultValue, setDefaultValue] = useState(0);
	const [defaultMonthlyValue, setDefaultMonthlyValue] = useState(0);

	const [website, setWebsite] = useState();
	const [email, setEmail] = useState();
	const [phone, setPhone] = useState();
	const [name, setName] = useState();
	const [id, setId] = useState();

	const [pages, setPages] = useState(defaultLandingPages);
	const [words, setWords] = useState(
		Number(process.env.REACT_APP_DEFAULT_WORDS)
	);
	const [seoReports, setSeoReports] = useState(1);
	const [bingCheckbox, setBingCheckbox] = useState(false);
	const [yahooCheckbox, setYahooCheckbox] = useState(false);
	const [yandexCheckbox, setYandexCheckbox] = useState(false);
	const [yandexMobileCheckbox, setYandexMobileCheckbox] = useState(false);
	const [youtubeCheckbox, setYoutubeCheckbox] = useState(false);

	const [validation, setValidation] = useState(false);
	const [validation2, setValidation2] = useState(false);
	const [terms, setTerms] = useState(false);

	useEffect(() => {
		switch (type) {
			case "startup":
				setDefaultValue(Number(process.env.REACT_APP_STARTUP_VALUE));
				setDefaultLandingPages(
					Number(process.env.REACT_APP_STARTUP_LANDING_PAGES)
				);
				setDefaultMonthlyValue(
					Number(process.env.REACT_APP_STARTUP_MONTHLY_VALUE)
				);
				setPages(Number(process.env.REACT_APP_STARTUP_PAGES));
				break;
			case "premium":
				setDefaultValue(Number(process.env.REACT_APP_PREMIUM_VALUE));
				setDefaultLandingPages(
					Number(process.env.REACT_APP_PREMIUM_LANDING_PAGES)
				);
				setDefaultMonthlyValue(
					Number(process.env.REACT_APP_PREMIUM_MONTHLY_VALUE)
				);
				setPages(Number(process.env.REACT_APP_PREMIUM_PAGES));
				break;
			case "enterprise":
				setDefaultValue(Number(process.env.REACT_APP_ENTERPRISE_VALUE));
				setDefaultLandingPages(
					Number(process.env.REACT_APP_ENTERPRISE_LANDING_PAGES)
				);
				setDefaultMonthlyValue(
					Number(process.env.REACT_APP_ENTERPRISE_MONTHLY_VALUE)
				);
				setPages(Number(process.env.REACT_APP_ENTERPRISE_PAGES));
				break;
			default:
				break;
		}
	}, [type]);

	const value = Math.floor(
		defaultValue +
			((words - defaultWards) / 100) * pricePerWards * pages +
			pricePerPage * (pages - defaultLandingPages)
	);
	const valueVat = Math.floor(
		(defaultValue +
			((words - defaultWards) / 100) * pricePerWards * pages +
			pricePerPage * (pages - defaultLandingPages)) *
			1.21
	);
	const monthlyRate = (
		(defaultMonthlyValue +
			pricePerPageMonthly * (pages - defaultLandingPages) +
			(seoReports - 1) * pricePerMonthlySeoReport +
			((bingCheckbox ? 1 : 0) +
				(yahooCheckbox ? 1 : 0) +
				(yandexCheckbox ? 1 : 0) +
				(yandexMobileCheckbox ? 1 : 0) +
				(youtubeCheckbox ? 1 : 0)) *
				12.5) *
		1.21
	).toFixed(0);

	const sendDetailsHandler = async () => {
		if (phone && email && website && name) {
			setStep(3);
			setValidation(false);
			axios
				.post(
					"https://us-central1-seorapportages.cloudfunctions.net/app/send-email",
					{
						website,
						phone,
						name,
						email,
					}
				)
				.then((res) => console.log(res.data))
				.catch((err) => console.log(err.message));
			const res = await addItem("quotes", { website, phone, name, email });
			console.log(res, res.data, res.id);
			setId(res.id);
		} else {
			setValidation(true);
		}
	};

	const sendOfferHandler = async (e) => {
		if (!terms) {
			setValidation2(true);
		} else {
			console.log("Before", id);
			const res = await updateItem("quotes", id, {
				package: type,
				pages,
				words,
				seoReports,
				bingCheckbox,
				yahooCheckbox,
				yandexCheckbox,
				yandexMobileCheckbox,
				youtubeCheckbox,
				total: value,
			});
			console.log("id", id, String(valueVat.toFixed(2)));
			console.log(window.location.pathname);
			axios
				.post(
					"https://us-central1-seorapportages.cloudfunctions.net/app/payment",
					{
						value: String(valueVat.toFixed(2)),
						description: "SEORAPPORTAGES package",
						redirectUrl: "https://seorapportages.nl/hartelijk-dank?id=" + id,
						webhookUrl:
							"https://us-central1-seorapportages.cloudfunctions.net/app/webhook/" +
							id,
					}
				)
				.then((res) => {
					console.log("res.data", res.data);
					window.location.href = res.data;
				})
				.catch((err) => console.log(err.message));
		}
	};

	const breadcrumbsHandler = (goToStep) => {
		console.log(goToStep);
		if (goToStep !== 3 || (phone && email && website && name))
			setStep(goToStep);
	};

	const getTitle = () => {
		if (step === 3) return "Kies extra opties voor het geselecteerde pakket";
		if (type === "startup")
			return "De startende ondernemer, regionale aanbieders";
		if (type === "premium")
			return "Meerdere diensten / producten en webshopeigenaren";
		if (type === "enterprise")
			return "Ondernemers met een zeer uitgebreide website en webshopeigenaren";
	};

	return (
		<div className='form'>
			<div className='row align-items-center'>
				<div className='col-md-9'>
					<div className='form-upper-title text-uppercase f-josefin text-secondary small'>
						{type}
					</div>
					<h2 className='form-title h3 pb-md-2'>{getTitle()}</h2>
				</div>
				<div className='col-md-3 border-top border-bottom'>
					<div className='form-price h1 text-center text-md-end mb-0'>
						€{valueVat}{" "}
						<span style={{ fontSize: "1rem" }} className='text-muted'>
							incl. BTW
						</span>
					</div>
					<div className='form-price h4 text-center text-md-end mb-3'>
						€{value}
					</div>
					<div className='form-price-lower h4 text-center text-md-end'>
						€{monthlyRate}/maand{" "}
						<span style={{ fontSize: "1rem" }} className='text-muted'>
							incl. BTW
						</span>
					</div>
				</div>
			</div>
			<nav aria-label='breadcrumb'>
				<ol className='breadcrumb'>
					<li
						className={"breadcrumb-item " + (step === 1 ? "active" : "")}
						onClick={() => breadcrumbsHandler(1)}>
						Pakketdetails
					</li>
					<li
						onClick={() => breadcrumbsHandler(2)}
						className={"breadcrumb-item " + (step === 2 ? "active" : "")}>
						Contact
					</li>
					<li
						className={"breadcrumb-item " + (step === 3 ? "active" : "")}
						aria-current='page'
						onClick={() => breadcrumbsHandler(3)}>
						Naar afrekenen
					</li>
				</ol>
			</nav>
			{/* Step 1 */}
			{step === 1 && (
				<>
					<Step1 type={type} />
					<div className='row row-footer'>
						<div className='col-lg-8'>
							<button
								onClick={() => setStep(2)}
								className='btn btn-primary text-uppercase'>
								BESTEL NU
							</button>
							<p className='d-inline-block ps-1'>
								<span className='text-muted'>Altijd opzegbaar</span>
							</p>
						</div>
						<div className='col'>
							<p className='pt-1 text-end' onClick={() => setActive(0)}>
								dicht
								<br />
								<i className='bi bi-x-circle'></i>
							</p>
						</div>
					</div>
				</>
			)}
			{/* Step 2*/}
			{step === 2 && (
				<>
					<Step2
						setName={setName}
						setEmail={setEmail}
						setWebsite={setWebsite}
						setPhone={setPhone}
						name={name}
						email={email}
						website={website}
						phone={phone}
						validation={validation}
					/>
					<div className='row'>
						<div className='col-lg-8'>
							<button
								onClick={sendDetailsHandler}
								className='btn btn-primary'
								type='submit'>
								VOLGENDE STAP
							</button>
						</div>
						<div className='col'>
							<p className='pt-1 text-end' onClick={() => setActive(0)}>
								dicht
								<br />
								<i className='bi bi-x-circle'></i>
							</p>
						</div>
					</div>
				</>
			)}
			{/* Step 3 */}
			{step === 3 && (
				<>
					<Form className='form-variables'>
						<Form.Label className='d-flex justify-content-between'>
							<span>Aantal landings pagina's</span>
							<span>
								<span className='text-danger'>{pages}</span> pagina's
							</span>
						</Form.Label>
						<Form.Range
							onChange={(e) => setPages(e.target.value)}
							min={defaultLandingPages}
							max={defaultLandingPages + 20}
							defaultValue={defaultLandingPages}
						/>
						<Form.Label className='d-flex justify-content-between'>
							<span>Aantal woorden</span>
							<span>
								<span className='text-danger'>{words}</span> woorden
							</span>
						</Form.Label>
						<Form.Range
							onChange={(e) => setWords(e.target.value)}
							min={defaultWards}
							max={defaultWards + 700}
							defaultValue={defaultWards}
							step='100'
						/>
						<Form.Label className='d-flex justify-content-between'>
							<span>Aantal SEO rapportages</span>
							<span>
								<span className='text-danger'>{seoReports}</span> rapportages
							</span>
						</Form.Label>
						<Form.Range
							onChange={(e) => setSeoReports(e.target.value)}
							min='1'
							max='4'
							defaultValue='1'
						/>
						<p>Zoekmachines</p>
						<label className='control control-checkbox' for='google-checkbox'>
							Google
							<input
								disabled={true}
								type='checkbox'
								id='google-checkbox'
								name='google-checkbox'
								checked={true}
							/>
							<div className='control_indicator'></div>
						</label>
						<label className='control control-checkbox' for='bing-checkbox'>
							Bing
							<input
								type='checkbox'
								id='bing-checkbox'
								name='bing-checkbox'
								onClick={(e) => setBingCheckbox(!bingCheckbox)}
								checked={bingCheckbox}
							/>
							<div className='control_indicator'></div>
						</label>
						<label className='control control-checkbox' for='yahoo-checkbox'>
							Yahoo
							<input
								type='checkbox'
								id='yahoo-checkbox'
								name='yahoo-checkbox'
								onClick={(e) => setYahooCheckbox(!yahooCheckbox)}
								checked={yahooCheckbox}
							/>
							<div className='control_indicator'></div>
						</label>
						<label className='control control-checkbox' for='yandex-checkbox'>
							Yandex
							<input
								type='checkbox'
								id='yandex-checkbox'
								name='yandex-checkbox'
								onClick={(e) => setYandexCheckbox(!yandexCheckbox)}
								checked={yandexCheckbox}
							/>
							<div className='control_indicator'></div>
						</label>
						<label
							className='control control-checkbox'
							for='yandex-mobile-checkbox'>
							Yandex Mobile
							<input
								type='checkbox'
								id='yandex-mobile-checkbox'
								name='yandex-mobile-checkbox'
								onClick={(e) => setYandexMobileCheckbox(!yandexMobileCheckbox)}
								checked={yandexMobileCheckbox}
							/>
							<div className='control_indicator'></div>
						</label>
						<label className='control control-checkbox' for='youtube-checkbox'>
							Youtube
							<input
								type='checkbox'
								id='youtube-checkbox'
								name='youtube-checkbox'
								onClick={(e) => setYoutubeCheckbox(!youtubeCheckbox)}
								checked={youtubeCheckbox}
							/>
							<div className='control_indicator'></div>
						</label>
					</Form>
					<div className='my-3'>
						<label
							className={
								"control control-checkbox " + (validation2 ? "text-danger" : "")
							}
							for='agreement'>
							Ik ga akkoord met de{" "}
							<a
								target='_blank'
								href='https://seorapportages.nl/algemene-voorwaarden'>
								Algemene Voorwaarden
							</a>{" "}
							van AMBISMART B.V.
							<input
								type='checkbox'
								id='agreement'
								name='agreement'
								onClick={(e) => setTerms(!terms)}
								checked={terms}
							/>
							<div className='control_indicator'></div>
						</label>
					</div>
					<div className='row'>
						<div className='col-lg-8'>
							<button
								onClick={() => sendOfferHandler()}
								className='btn btn-primary'
								type='submit'>
								Naar afrekenen
							</button>
						</div>
						<div className='col'>
							<p className='pt-1 text-end' onClick={() => setActive(0)}>
								dicht
								<br />
								<i className='bi bi-x-circle'></i>
							</p>
						</div>
					</div>
				</>
			)}
		</div>
	);
}

export default App;
