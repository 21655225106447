import "./App.scss";
// import "./theme.css";
import React, { useState, useEffect } from "react";
import Configurator from "./components/Configurator";
import { getItem } from "./functions";
import axios from "axios";
import { updateItem } from "./functions";

function App() {
	const [active, setActive] = useState();
	const [step, setStep] = useState(1);
	const [page, setPage] = useState();

	const pathname = window.location.pathname;

	const [type, setType] = useState("");

	const [additionalForm, setAdditionalForm] = useState();
	const [clientVatNumber, setClientVatNumber] = useState();
	const [companyName, setCompanyName] = useState();
	const [companyAddress, setCompanyAddress] = useState();
	const [companyVenue, setCompanyVenue] = useState();
	const [zip, setZip] = useState();

	useEffect(() => {
		(async () => {
			if (pathname.includes("hartelijk-dank")) {
				const queryString = window.location.search;
				const urlParams = new URLSearchParams(queryString);
				const id = urlParams.get("id");
				console.log(id);
				const data = await getItem("quotes", id);
				if (data.payment === "paid") {
					setPage("complete");
					console.log("data", data);
					if (!data.additionalForm) {
						setAdditionalForm("collect");
					}
					if (!data.completionEmail) {
						axios
							.post(
								"https://us-central1-seorapportages.cloudfunctions.net/app/send-completed-email",
								{
									email: data.email,
									phone: data.phone,
									name: data.name,
									orderId: id,
									type: data.package,
									pages: data.pages,
									words: data.words,
									seoReports: data.seoReports,
									total: data.total,
								}
							)
							.then((res) => {
								updateItem("quotes", id, { completionEmail: true });
								console.log(res.data);
							})
							.catch((err) => console.log(err.message));
					}
				} else {
					window.location.href = "https://seorapportages.nl";
				}
			} else {
				setPage("home");
			}
		})();
	}, [pathname]);

	const additionalFormHandler = async () => {
		console.log("async");
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const id = urlParams.get("id");
		console.log(id, clientVatNumber, companyAddress, companyVenue, zip);
		const data = await getItem("quotes", id);
		let res = await updateItem("quotes", id, {
			clientVatNumber,
			companyName,
			companyAddress,
			companyVenue,
			zip,
			additionalForm: "done",
		});
		setAdditionalForm("success");
		axios.post(
			"https://us-central1-seorapportages.cloudfunctions.net/app/send-email-extradetails",
			{
				clientVatNumber,
				companyAddress,
				companyName,
				companyVenue,
				zip,
				email: data.email,
				phone: data.phone,
				name: data.name,
				website: data.website,
			}
		);
	};

	return (
		<>
			{page === "complete" && (
				<div>
					{additionalForm !== "success" && (
						<h3 className='mb-4'>
							Extra aanvullende gegevens en opmerkingen van uw bedrijf graag
							hier invullen
						</h3>
					)}
					{additionalForm === "collect" && (
						<>
							<input
								className='form-control mb-3'
								onInput={(e) => setClientVatNumber(e.target.value)}
								placeholder='BTW nummer'
							/>
							<input
								className='form-control mb-3'
								onInput={(e) => setCompanyName(e.target.value)}
								placeholder='Bedrijfsnaam'
							/>
							<input
								className='form-control mb-3'
								onInput={(e) => setCompanyAddress(e.target.value)}
								placeholder='Bedrijfsadres'
							/>
							<div className='row gx-2'>
								<div className='col-8'>
									<input
										className='form-control mb-3'
										onInput={(e) => setCompanyVenue(e.target.value)}
										placeholder='Plaats'
									/>
								</div>
								<div className='col-4'>
									<input
										className='form-control'
										onInput={(e) => setZip(e.target.value)}
										placeholder='Postcode'
									/>
								</div>
							</div>
							<button
								className='btn btn-primary mt-4'
								onClick={additionalFormHandler}>
								STUUR INFO
							</button>
						</>
					)}
					{additionalForm === "success" && (
						<>
							<h2>Bedankt voor de aanvullende informatie.</h2>
						</>
					)}
				</div>
			)}
			{page === "home" && (
				<section className='services-wrap bg-light pt-lg-7'>
					<div
						className={
							`container services step-${step} ` +
							(active ? `service-active service-${active}-active ` : " ")
						}>
						<div className='row gx-5 px-lg-4'>
							<div className='col-lg-4 mb-4 mb-lg-0'>
								<div className='bg-white rounded shadow service service-1'>
									<div className='service-view-wrap'>
										<div className='service-brief-view text-center py-5 '>
											<p className='text-primary mb-0 f-josefin small'>
												STARTUP
											</p>
											<h2 className='h1 text-primary mb-0'>
												€{parseInt(process.env.REACT_APP_STARTUP_VALUE * 1.21)}{" "}
												<span
													style={{ fontSize: "1rem" }}
													className='text-muted'>
													incl. BTW
												</span>
											</h2>
											<h2 className='h4 text-primary mb-0'>
												€{process.env.REACT_APP_STARTUP_VALUE}{" "}
												<span
													style={{ fontSize: "1rem" }}
													className='small text-muted'>
													excl. BTW
												</span>
											</h2>
											<p className='h6 mb-3'>
												éénmalige investering en daarna slechts €50 per maand
											</p>
											<button
												className='btn btn-primary mb-3'
												onClick={() => {
													setActive(1);
													setStep(2);
													setType("startup");
												}}>
												BESTEL NU
											</button>
											<button
												className='text-decoration-none d-block text-dark h5 btn btn-link'
												onClick={() => {
													setStep(1);
													setActive(1);
													setType("startup");
												}}>
												Meer pakketdetails{" "}
												<i className='bi bi-chevron-right'></i>
											</button>
										</div>
										<div className='service-large-view px-lg-4 px-3'>
											{active === 1 && (
												<Configurator
													type={type}
													setStep={setStep}
													step={step}
													setActive={setActive}
												/>
											)}
										</div>
									</div>
								</div>
							</div>
							<div className='col-lg-4 mb-4 mb-lg-0'>
								<div className='bg-white rounded shadow service service-2'>
									<div className='service-view-wrap'>
										<div className='service-brief-view text-center py-5 '>
											<p className='text-primary mb-0 f-josefin small'>
												PREMIUM
											</p>
											<h2 className='h1 text-primary mb-0'>
												€{parseInt(process.env.REACT_APP_PREMIUM_VALUE * 1.21)}{" "}
												<span
													style={{ fontSize: "1rem" }}
													className='small text-muted'>
													incl. BTW
												</span>
											</h2>
											<h2 className='h4 text-primary mb-0'>
												€{process.env.REACT_APP_PREMIUM_VALUE}{" "}
												<span
													style={{ fontSize: "1rem" }}
													className='small text-muted'>
													excl. BTW
												</span>
											</h2>
											<p className='h6 mb-3'>
												éénmalige investering en daarna slechts €100 per maand
											</p>
											<button
												onClick={() => {
													setActive(2);
													setStep(2);
													setType("premium");
												}}
												className='btn btn-secondary mb-3 text-white'>
												BESTEL NU
											</button>
											<button
												className='text-decoration-none d-block text-dark h5 btn btn-link'
												onClick={() => {
													setActive(2);
													setStep(1);
													setType("premium");
												}}>
												Meer pakketdetails{" "}
												<i className='bi bi-chevron-right'></i>
											</button>
										</div>
										<div className='service-large-view px-lg-4 px-3'>
											{active === 2 && (
												<Configurator
													type={type}
													setStep={setStep}
													step={step}
													setActive={setActive}
												/>
											)}
										</div>
									</div>
								</div>
							</div>
							<div className='col-lg-4'>
								<div className='bg-white rounded shadow service service-3'>
									<div className='service-view-wrap'>
										<div className='service-brief-view text-center py-5'>
											<p className='text-primary mb-0 f-josefin small'>
												ENTERPRISE
											</p>
											<h2 className='h1 text-primary mb-0'>
												€
												{parseInt(
													process.env.REACT_APP_ENTERPRISE_VALUE * 1.21
												)}{" "}
												<span
													style={{ fontSize: "1rem" }}
													className='text-muted'>
													incl. BTW
												</span>
											</h2>
											<h2 className='h4 text-primary mb-0'>
												€{process.env.REACT_APP_ENTERPRISE_VALUE}{" "}
												<span
													style={{ fontSize: "1rem" }}
													className='small text-muted'>
													excl. BTW
												</span>
											</h2>
											<p className='h6 mb-3'>
												éénmalige investering en daarna slechts €150 per maand
											</p>
											<button
												className='btn btn-primary mb-3'
												onClick={() => {
													setActive(3);
													setStep(2);
													setType("enterprise");
												}}>
												BESTEL NU
											</button>
											<button
												className='text-decoration-none d-block text-dark h5 btn btn-link'
												onClick={() => {
													setActive(3);
													setStep(1);
													setType("enterprise");
												}}>
												Meer pakketdetails{" "}
												<i className='bi bi-chevron-right'></i>
											</button>{" "}
										</div>
										<div className='service-large-view px-lg-4 px-3'>
											{active === 3 && (
												<Configurator
													type={type}
													setStep={setStep}
													step={step}
													setActive={setActive}
												/>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			)}
		</>
	);
}

export default App;
